import {
  DeliveryInformationModalComponent
} from './order-articles-list/order-articles-list/components/delivery-information-modal/delivery-information-modal.component';
import {ComponentFactoryResolver, Injectable, Type, ViewContainerRef} from "@angular/core";
import {
  OrderArticlesListRow,
  OrderArticlesListRowInterface,
  pageBreakKey,
  RowTypes
} from "./order-articles-list/order-articles-list.interface";
import {ActionType} from "./controls/action-type.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {OrdersService} from "./orders.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ExtraItemsService} from "./order-articles-list/order-articles-list/components/extra-row/extra-items.service";
import {OrderArticleService} from "../core/services/order-article/order-article.service";
import {TranslateService} from "@ngx-translate/core";
import {NgProgress} from "ngx-progressbar";
import { AddItemHelperService } from "../shared/services/add-item-helper/add-item-helper.service";
import {FullScreenModeService} from "../core/services/full-screen-mode/full-screen-mode.service";
import {ListModeSwitchService} from "../shared/components/list-mode-switch/list-mode-switch.service";
import {PriceListStoreHandlerService} from "../core/services/price-list/price-list-store-handler.service";
import {SelectedRowsService} from "./order-articles-list/services/selected-rows/selected-rows.service";
import {UserService} from "../core/services/user/user.service";
import {OrderArticlesListService} from "./order-articles-list/services/order-articles-list/order-articles-list.service";
import {DiscountModalOpenerService} from "./discount-modal/discount-modal-opener.service";
import {OpenGroupsService} from "./order-articles-list/order-articles-list/components/group/open-groups/open-groups.service";
import {OrderArticleType} from "../core/models/order-article.model";
import {OtherSupplierItemModalComponent} from "./custom-article-modal/other-supplier-item-modal/other-supplier-item-modal.component";
import {NonStandardItemModalComponent} from "./custom-article-modal/non-standard-item-modal/non-standard-item-modal.component";
import {
  ConfiguratorModalComponent,
  ConfiguratorModalOnModifyDiscountsEventInterface
} from "../configurator-modal/configurator-modal.component";
import {ConfiguratorModalActionType} from "../core/enums/configurator-modal-action-type.enum";
import {ExtraListElementsTypes} from "./order-articles-list/order-articles-list/components/extra-row/extra-row-types.enum";
import {
  CreateExtraListElementInterface, ExtraListElementRowInterface,
  PageBreakGroupInterface
} from "./order-articles-list/order-articles-list/components/extra-row/extra-items.model";
import {Observable, of, zip} from "rxjs";
import {UngroupEventInterface} from "./order-articles-list/order-articles-list/components/group/group.component";
import {CreateDocumentModalComponent} from "./create-document-modal/create-document-modal.component";
import {GenericModalTypes} from "../ui-elements/generic-modal/generic-modal-types";
import {SelectClientModalComponent} from "../ui-elements/select-client-modal/select-client-modal.component";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {ClientInterface} from "../core/models/client.model";
import {OrderAdditionalDetailTypes} from "./additional-order-details/order-additional-details-types.enum";
import {OrderItemsPasteEvent} from "./order-items-clipboard/order-items-clipboard.interface";
import {FullOrderArticleInterface} from "../core/models/full-order-article.model";
import {LoaderComponent} from "../ui-elements/loader/loader.component";
import {CustomArticleModalModel} from "./custom-article-modal/custom-article-modal.model";
import {AbstractViewComponent} from "./view-component.model";
import { ToastService } from "../ui-elements/toast/toast.service";
import { OrderMigrationState } from "../core/enums/order.state.enum";
import { GROUP_LIKE_ROW_TYPES } from '../core/services/order-items-group/order-items-group.service';
import { OrderInterface } from '../core/models/order.model';
import { SelectionMenuService } from '../selection-menu/selection-menu.service';
import { PageBreakInterface } from '../core/models/page-break.model';

export interface CanModifyProductViewComponentInterface {
  selected: OrderArticlesListRow[];

  modifyProduct();
}

export enum RemoveActionType {
  UNGROUP,
  REMOVE,
}

export interface RemoveRowInterface {
  id: number;
  rowType: RowTypes;
  groupLevel?: number;
}

@Injectable()
export abstract class CanModifyProductViewComponent extends AbstractViewComponent implements CanModifyProductViewComponentInterface {
  selected: OrderArticlesListRow[] = [];
  actionTypes = ActionType;
  sendingToAxInProgress$ = this.ordersService.sendingToAxInProgressAsObservable();

  protected constructor(
    protected selectionMenuService: SelectionMenuService,
    protected route: ActivatedRoute,
    protected ordersService: OrdersService,
    protected modalService: NgbModal,
    protected extraListElementsService: ExtraItemsService,
    protected orderArticleService: OrderArticleService,
    protected translator: TranslateService,
    protected toastService: ToastService,
    protected progressService: NgProgress,
    protected addItemHelperService: AddItemHelperService,
    protected fullScreenModeService: FullScreenModeService,
    protected listModeSwitchService: ListModeSwitchService,
    protected priceListStoreHandlerService: PriceListStoreHandlerService,
    protected selectedRowsService: SelectedRowsService,
    protected router: Router,
    protected userService: UserService,
    protected orderArticleListService?: OrderArticlesListService,
    protected discountModalOpener?: DiscountModalOpenerService,
    protected openGroupsService?: OpenGroupsService,
    protected resolver?: ComponentFactoryResolver,
    protected container?: ViewContainerRef,
  ) {
    super(
      route,
      ordersService,
      extraListElementsService,
      fullScreenModeService,
      router,
      userService,
      selectedRowsService,
      listModeSwitchService,
      priceListStoreHandlerService,
      openGroupsService
    );
    this.subscriptions.add(
      this.selectedRowsService.getSelectedRowsAsObservable().subscribe(selectedRows => {
        this.selected = selectedRows;
      })
    );
  }

  modifyProduct() {
    const selectedItem = this.selected.find(row => row.rowType === RowTypes.PRODUCT);
    if (selectedItem) {
      const fullOrderArticle = this.articles.find(filterableArticle => filterableArticle.orderArticle.id === selectedItem.id);
      const {type} = fullOrderArticle.orderArticle;
      let modalRef;

      switch (type) {
        case OrderArticleType.OTHER_SUPPLIER_ITEM:
          this.openModifyCustomItemModal(fullOrderArticle.orderArticle.id, OtherSupplierItemModalComponent);

          window.dataLayer.push({
            event: 'modifyOtherSupplierItem',
            order: this.order.id,
            item_id: fullOrderArticle.orderArticle.id,
            item_name: fullOrderArticle.orderArticle.code,
            item_variant: fullOrderArticle.orderArticle.fullCode,
          });
          break;
        case OrderArticleType.CUSTOM_ITEM_TYPE:
          this.openModifyCustomItemModal(fullOrderArticle.orderArticle.id, NonStandardItemModalComponent);

          window.dataLayer.push({
            event: 'modifyNonStandardItem',
            order: this.order.id,
            item_id: fullOrderArticle.orderArticle.id,
            item_name: fullOrderArticle.orderArticle.code,
            item_variant: fullOrderArticle.orderArticle.fullCode,
          });
          break;
        default:
          modalRef = this.modalService.open(ConfiguratorModalComponent, {
            windowClass: 'configurator-modal',
            keyboard: false,
          });
          modalRef.componentInstance.system = selectedItem.item.system;
          modalRef.componentInstance.order = this.order;
          modalRef.componentInstance.group = selectedItem.pageBreak;
          modalRef.componentInstance.orderArticleId = fullOrderArticle.orderArticle.id;
          modalRef.componentInstance.articleRow = selectedItem;
          modalRef.componentInstance.action = ConfiguratorModalActionType.EDIT;
          const quantity = fullOrderArticle.orderArticle.configurationQuantity || fullOrderArticle.orderArticle.quantity;
          modalRef.componentInstance.quantity = quantity;
          if (selectedItem.item.category) {
            const [categoryPath] = selectedItem.item.category.split('/');
            modalRef.componentInstance.cataloguePath = {
              child: {
                name: categoryPath ? categoryPath.replace(/_/g, ' ') : null,
              },
            };
          }

          // @ts-ignore: global window.dataLayer
          window.dataLayer.push({
            event: 'modifyStandardItem',
            url: window.location.href,
            company: this.user.companyName || 'none',
            user_id: this.user.id,
            role: this.user.role.name,
            ax: this.user.axClientCode || 'none',
            item_id: fullOrderArticle.orderArticle?.id,
            item_name: fullOrderArticle.orderArticle?.shortText || 'none',
            item_variant: fullOrderArticle.orderArticle?.fullCode || 'none',
            item_category: selectedItem.item.system,
            quantity: quantity,
          });

          modalRef.componentInstance.save.subscribe(() => {
            this.load();
          });

          modalRef.componentInstance.modifyDiscounts.subscribe((event: ConfiguratorModalOnModifyDiscountsEventInterface) => {
            this.onDiscount();
          });
          break;
      }
    }
  }

  private getExtraItemPosition(lastSelectedItem) {
    let position = 0;
    const orderArticles = this.articles.map(({orderArticle}) => orderArticle);
    const mergedItems = [...[].concat.apply([], orderArticles), ...this.extraItems];

    if (mergedItems.length) {
      if (lastSelectedItem) {
        position = lastSelectedItem.position;
        mergedItems.map(item => {
          if (item.position > position) {
            item.position += 1;
          }
          return item;
        });
      } else {
        position = Math.max.apply(
          Math,
          mergedItems.map(item => item.position)
        );
      }
      position = position + 1;
    }

    this.updateItemsOnlyPositions({articles: orderArticles, extraElements: this.extraItems});
    return position;
  }

  private createExtraItem(
    type: ExtraListElementsTypes,
    firstSelectedItem: OrderArticlesListRow,
    lastSelectedItem: OrderArticlesListRow,
    parent?: OrderArticlesListRow
  ): CreateExtraListElementInterface {
    const getParent = (parent?: OrderArticlesListRow): number => {
      if (parent) {
        return parent.id;
      }

      let parentInner = null;

      if (firstSelectedItem) {
        parentInner = firstSelectedItem[pageBreakKey(firstSelectedItem)] ? firstSelectedItem[pageBreakKey(firstSelectedItem)].id : null;
      }
      return parentInner;
    };
    return {
      type: type,
      title: null,
      order: this.order.id,
      parent: getParent(parent),
      position: this.getExtraItemPosition(lastSelectedItem),
    };
  }

  onExtraItemAdd(type: ExtraListElementsTypes) {
    window.dataLayer.push({
      event: 'addExtra',
      order: this.order.id,
      extra_type: ExtraListElementsTypes[type],
    });

    const {firstSelectedItem, lastSelectedItem, parent} = this.selectedRowsService.getSelectedItemsAndParent(this.selected);

    const extraItem = this.createExtraItem(type, firstSelectedItem, lastSelectedItem, parent);

    this.extraListElementsService.create(extraItem).subscribe((responseExtraItem: PageBreakGroupInterface) => {
      responseExtraItem.justCreated = true;
      if (parent) {
        this.openGroupsService.open(parent);
      }
      this.selectedRowsService.resetSelectedRows();
      this.extraItems = [...this.extraItems, responseExtraItem];
    });
  }

  onGroupItems() {
    let lastSelectedItem;
    const lowestGroupLevel = Math.min.apply(
      Math,
      this.selected.map(({groupLevel}) => groupLevel)
    );
    const itemsToGroup = this.selected.filter(({groupLevel}) => groupLevel === lowestGroupLevel);
    if (this.selected.length > 0) {
      lastSelectedItem = this.selected[this.selected.length - 1];
    }

    window.dataLayer.push({
      event: 'groupItems',
      order: this.order.id,
      item_count: itemsToGroup.length,
    });

    const [firstSelectedItem] = itemsToGroup;

    const extraItem = this.createExtraItem(ExtraListElementsTypes.GROUP, firstSelectedItem, lastSelectedItem);

    this.extraListElementsService.create(extraItem).subscribe((responseExtraItem: PageBreakGroupInterface) => {
      responseExtraItem.justCreated = true;
      this.updateItemsPositionsAndPageBreak(splitElements(itemsToGroup), responseExtraItem);
      this.selectedRowsService.resetSelectedRows();
      this.extraItems = [...this.extraItems, responseExtraItem];
    });
  }

  private updateItemsOnlyPositions({articles, extraElements}): Observable<any> {
    const draggableArticles = articles.filter(article => !article.belongsToLockedGroup);

    const observables = [];

    if (draggableArticles.length) {
      observables.push(this.orderArticleService.updateMultiple(generateUpdateRowsRequestObject(draggableArticles)));
    }

    if (extraElements.length) {
      observables.push(this.extraListElementsService.updateMultiple(generateUpdateRowsRequestObject(extraElements)));
    }
    return zip(...observables);
  }

  /**
   * Update/group articles and extraElements positions and pageBreak values request and local update
   */
  private updateItemsPositionsAndPageBreak({articles, extraElements}, group: PageBreakGroupInterface) {
    if (articles.length) {
      this.orderArticleService.updateMultiple(generateUpdateRowsRequestObject(articles, group !== null ? group.id : null)).subscribe();
    }
    if (extraElements.length) {
      this.extraListElementsService
        .updateMultiple(generateUpdateRowsRequestObject(extraElements, group !== null ? group.id : null))
        .subscribe();
    }
    // Update rows to avoid table data refresh and new http call to get articles and page breaks
    articles.forEach(article => {
      const found = this.articles.find(item => item.orderArticle.id === article.id);
      if (found) {
        found.orderArticle.pageBreak = group as PageBreakInterface;
      }
    });
    extraElements.forEach(extraElement => {
      const found = this.extraItems.find(item => item.id === extraElement.id);
      if (found) {
        found.parent = group;
      }
    });
    this.articles = [...this.articles];
    this.extraItems = [...this.extraItems];
  }

  onExtraItemUpdate(extraRow) {
    const index = this.extraItems.findIndex(extraItem => extraItem.id === extraRow.id, 0);
    if (index > -1) {
      this.extraItems[index] = extraRow;
    }
  }

  onRemove(rows: RemoveRowInterface[], type = RemoveActionType.REMOVE) {
    window.dataLayer.push({
      event: type === RemoveActionType.REMOVE ? 'removeItems' : 'ungroupItems',
      order: this.order.id,
      item_count: rows.length || 0,
    });

    const wasTerminated = this.cancelInFlightArticlesRequest();

    this.orderArticleListService.deleteRows(this.order.id, rows, type).subscribe({
      next: () => {
        this.load();
        this.updateLastUpdatedPageBreak(rows);
      },
      error: () => {
        if (wasTerminated) {
          // allow to make articles request again
          this.reloading = false;
          this.refreshArticlesData();
        }
      }
    });
  }

  onUngroup(items: UngroupEventInterface[]) {
    const groups = items
      .map(({group}) => group)
      .sort((prev, next) => {
        return prev.groupLevel - next.groupLevel;
      }); // sort is necessary to avoid error while deleting nested/related groups

      const wasTerminated = this.cancelInFlightArticlesRequest();

    this.orderArticleListService.deleteRows(this.order.id, groups, RemoveActionType.UNGROUP).subscribe({
      next: () => {
        // allow to make articles request again
        this.reloading = false;
        const mergedChildren = [].concat.apply(
          [],
          items.map(({children}) => children)
        );
        this.orderArticleListService.updateOnlyRowPositions(mergedChildren, false).subscribe(() => this.load());
        this.updateLastUpdatedPageBreak(groups);
      },
      error: () => {
        if (wasTerminated) {
          // allow to make articles request again
          this.reloading = false;
          this.refreshArticlesData();
        }
      }
    });
  }

  onUploadFile(files) {
    const [file] = files;
    const url = window.location.pathname;
    if (file) {
      // getting file extension for KPI tracking
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop().toUpperCase();

      // this.progressService.start();
      this.loading = true;
      this.ordersService.import(this.order.id, file).subscribe(
        result => {
          const importedItemsCount = result['all'] ? result['all'] : 0;
          const translationKey = importedItemsCount > 1 ? 'SUCCESS_PLURAL' : 'SUCCESS_SINGULAR';
          this.translator
            .get(`ORDER_PRODUCTS_LIST.IMPORT.${translationKey}`, {
              count: importedItemsCount,
              url,
            })
            .subscribe(translation => {
              this.toastService.success(translation);
            });
          this.ordersService.importInProgress$.next(false);

          // @ts-ignore: global window.dataLayer
          window.dataLayer.push({
            event: ['OBX', 'OBK'].includes(extension) ? `import${extension}` : 'importUnknown',
            url: window.location.href,
            order: this.orderId,
            item_count: importedItemsCount,
            file_name: file.name,
          });

          /*Refresh order articles*/
          super.load();
          // this.progressService.done();
        },
        error => {
          console.log(error);
          const fileExtension = (file.name as string)
            .split('.')
            .pop()
            .toUpperCase();
          this.translator.get('ORDER_PRODUCTS_LIST.IMPORT.ERROR', {name: file.name, extension: fileExtension}).subscribe(translation => {
            this.toastService.danger(translation);
            // @ts-ignore: global window.dataLayer
            window.dataLayer.push({
              event: 'importFailed',
              url: window.location.href,
              order: this.orderId,
              item_count: 0,
              file_name: file.name,
              error:
                error.error.data && error.error.data.error && error.error.data.error.code
                  ? error.error.data.error.code
                  : error.status || 'unknown',
            });
          });
          // this.progressService.done();
          this.loading = false;
          this.ordersService.importInProgress$.next(false);
        }
      );
    }
  }

  onRetryImport() {
    this.loading = true;
    this.importState = this.importStates.IMPORTING;
    this.ordersService
      .retryImport(this.order.id)
      .noCache()
      .subscribe(result => {
        this.loading = false;
        if (result === this.importStates.IMPORTING) {
          this.ordersService.importInProgress$.next(false);
          this.startRefreshOrderImportStatusPolling();
          this.translator.get(`ORDER_PRODUCTS_LIST.IMPORT.IN_PROGRESS`).subscribe(translation => {
            this.toastService.success(translation);
          });
        } else {
          this.importState = this.importStates.ERROR;
          this.translator.get(`ORDER_PRODUCTS_LIST.IMPORT.STUCK`).subscribe(translation => {
            this.toastService.success(translation);
          });
        }
      });
  }

  onContinueImport() {
    this.loading = true;
    this.importState = this.importStates.IMPORTING;
    this.ordersService.continueImport(this.order.id).subscribe(result => {
      this.loading = false;
      if (result === this.importStates.IMPORTING) {
        this.ordersService.importInProgress$.next(false);
        this.startRefreshOrderImportStatusPolling();
        this.translator.get(`ORDER_PRODUCTS_LIST.IMPORT.IN_PROGRESS`).subscribe(translation => {
          this.toastService.success(translation);
        });
      } else {
        this.importState = this.importStates.ERROR;
        this.translator.get(`ORDER_PRODUCTS_LIST.IMPORT.STUCK`).subscribe(translation => {
          this.toastService.success(translation);
        });
      }
    });
  }

  onRetryMigration() {
    this.loading = true;
    this.order.migrationState = OrderMigrationState.MIGRATING;
    this.migratedPercent = 0;

    this.ordersService
      .retryMigration(this.order.id)
      .noCache()
      .subscribe(result => {
        this.loading = false;
        if (result === OrderMigrationState.MIGRATING) {
          this.startRefreshOrderMigrationStatusPolling();
          this.translator.get(`ORDER_PRODUCTS_LIST.MIGRATION.IN_PROGRESS`).subscribe(translation => {
            this.toastService.success(translation);
          });
        } else {
          this.order.migrationState = OrderMigrationState.FAILED;
          this.translator.get(`ORDER_PRODUCTS_LIST.MIGRATION.STUCK`).subscribe(translation => {
            this.toastService.danger(translation);
          });
        }
      });
  }

  onDiscount() {
    window.dataLayer.push({
      event: 'modifyOrderDiscounts',
      order: this.order.id,
    });

    this.discountModalOpener.open(
      this.order.id,
      this.selected.filter(item => item.rowType === RowTypes.PRODUCT).map(item => item.id),
      () => this.load()
    );
  }

  onCreateDocument() {
    const modalRef = this.modalService.open(CreateDocumentModalComponent, {
      windowClass: GenericModalTypes.GREY + ' create-document',
      size: 'xl',
      scrollable: true
    });
    modalRef.componentInstance.order = this.order;
  }

  onAddClient() {
    const modalRef = this.modalService.open(SelectClientModalComponent, {
      windowClass: `${GenericModalTypes.GREEN}  create-document`,
      size: 'lg',
      scrollable: true
    });
    modalRef.componentInstance.order = this.order;
    modalRef.componentInstance.clientSelect
      .pipe(
        debounceTime(400),
        switchMap((selectedClient: ClientInterface) => {
          const client = selectedClient && selectedClient.id ? selectedClient.id : null;
          return this.ordersService.update(this.order.id, {client});
        })
      )
      .subscribe(({client}) => (this.order.client = client));
  }

  onAddItem() {
    const group = this.selected.find(row => row.rowType === RowTypes.GROUP);

    window.dataLayer.push({
      event: 'addStandard',
      order: this.order.id,
    });

    this.addItemHelperService.itemAddToOrderClicked(this.order.id, group ? group.id : null);
  }

  onSendOrder(type: OrderAdditionalDetailTypes) {
  }

  onPaste(event: OrderItemsPasteEvent) {
    window.dataLayer.push({
      event: 'pasteItem',
      order: this.order.id,
    });

    this.load();
  }

  onApplyCustomSaleDiscount(event: OrderArticlesListRowInterface) {
  }

  onUpdateItems(items: OrderArticlesListRow[], updatedArticles: FullOrderArticleInterface[]) {
    this.articles = this.articles.map(article => {
      const found = updatedArticles.find(updatedArticle =>
        updatedArticle.orderArticle.id === article.orderArticle.id
      );
      if (found) {
        article.orderArticle.pageBreak = found.orderArticle.pageBreak;
        article.orderArticle.position = found.orderArticle.position;
      }
      return article;
    });
    this.extraItems = this.extraItems.map(extraItem => {
      const found = items
        .filter(item => item.rowType !== RowTypes.PRODUCT)
        .find(item => item.id === extraItem.id);
      if (found) {
        extraItem.position = found.position;
        extraItem.parent = found.parent;
      }
      return extraItem;
    });
  }

  private moveOtherItemsByOnePosition(lastSelectedItem, position): Observable<any> {
    const orderArticles = this.articles.map(({orderArticle}) => orderArticle);
    const mergedItems = [...[].concat.apply([], orderArticles), ...this.extraItems];

    if (!mergedItems.length) {
      return of({});
    }

    if (mergedItems.length) {
      if (lastSelectedItem) {
        position = lastSelectedItem.position;
        mergedItems.map(item => {
          if (item.position > position) {
            item.position += 1;
          }
          return item;
        });
      }
    }

    return this.updateItemsOnlyPositions({
      articles: orderArticles,
      extraElements: this.extraItems,
    });
  }

  onAddNonStandardItem() {
    window.dataLayer.push({
      event: 'addNonStandard',
      order: this.order.id,
    });

    this.openNewCustomItemModal(NonStandardItemModalComponent);
  }

  onOtherSupplierItemAdd() {
    window.dataLayer.push({
      event: 'addOtherSupplier',
      order: this.order.id,
    });

    this.openNewCustomItemModal(OtherSupplierItemModalComponent);
  }

  onExportOrderToPdf(loader: LoaderComponent) {
    window.dataLayer.push({
      event: 'saveOrderToPdf',
      order: this.order.id,
    });

    loader.show();
    this.ordersService
      .exportToPdf(this.orderId, this.order.title) //
      .add(() => loader.hide());
  }

  private openNewCustomItemModal(component: Type<CustomArticleModalModel>) {
    const factory = this.resolver.resolveComponentFactory(component);
    const contentRef = this.container.createComponent(factory);

    const {lastSelectedItem, parent: selectedGroup} = this.selectedRowsService.getSelectedItemsAndParent(
      this.selected
    );

    let position;
    if (lastSelectedItem) {
      position = lastSelectedItem.position + 1;
      contentRef.instance.position = position;
    }
    contentRef.instance.selectedOrder = this.order;
    contentRef.instance.selectedGroup = selectedGroup;
    contentRef.instance.saveToOtherOrder.subscribe(
      () => {
        contentRef.instance.close();
        contentRef.destroy();
      },
      () => {
        contentRef.instance.close();
        contentRef.destroy();
      }
    );
    contentRef.instance.save
      .pipe(
        switchMap(() => {
          return this.moveOtherItemsByOnePosition(lastSelectedItem, position);
        })
      )
      .subscribe({
        next: () => {
          this.load();
          if (selectedGroup) {
            this.openGroupsService.open(selectedGroup);
          }
          contentRef.instance.close();
          contentRef.destroy();
        },
        error: () => {
          contentRef.instance.close();
          contentRef.destroy();
        }
      });

    contentRef.changeDetectorRef.detectChanges();
    contentRef.instance.open();
  }

  private openModifyCustomItemModal(articleId: number, component: Type<CustomArticleModalModel>) {
    const factory = this.resolver.resolveComponentFactory(component);
    const contentRef = this.container.createComponent(factory);

    contentRef.instance.selectedOrder = this.order;
    contentRef.instance.save.subscribe({
      next: () => {
        this.load();
        contentRef.instance.close();
        contentRef.destroy();
      },
      error: () => {
        contentRef.instance.close();
        contentRef.destroy();
      },
    });
    contentRef.instance.id = articleId;

    contentRef.changeDetectorRef.detectChanges();
    contentRef.instance.open();
  }

  onDateChange(date) {
    this.ordersService.update(this.order.id, {dispatchDate: date}).subscribe({
      next: order => {
        this.orderUpdate$.next({ ...this.order, ...order });
      },
      error: error => {
        console.log(error);
      }
    });
  }

  // Update local order articles extra items on each drop to avoid unnecessary DOM rendering
  onDrop(flattenRows: OrderArticlesListRow[]) {
    this.articles.forEach(({orderArticle}) => {
      const found = flattenRows.find(row => row.id === orderArticle.id);
      if (found) {
        orderArticle.position = found.position;
        orderArticle.pageBreak = found.pageBreak;
      }
    });

    this.extraItems.forEach(item => {
      const found = flattenRows.find(row => row.id === item.id);
      if (found) {
        item.position = found.position;
        item.parent = found.parent;
        item.groupLevel = found.groupLevel;
      }
    });
  }

  onShowAdditionalInfo(order: OrderInterface) {
    const modalRef = this.modalService.open(DeliveryInformationModalComponent, {
      windowClass: 'delivery-information-modal',
      keyboard: true,
      size: 'lg',
    });

    modalRef.componentInstance.order = order;
  }

  updateLastUpdatedPageBreak(rows: RemoveRowInterface[]) {
    this.userService.getUser().subscribe(user => {
      const { lastUpdatedPageBreak } = user;
      if (rows.some(row => row.id === lastUpdatedPageBreak?.id)) {
        this.selectionMenuService.resetSelectedGroup();
      }
    });
  }
}

export function generateUpdateRowsRequestObject(items, group?: number) {
  return items.map(item => {
    const pageBreak: number = group || (item.pageBreak ? item.pageBreak.id : null) || (item.parent ? item.parent.id : null);

    return {
      id: item.id,
      position: item.position,
      pageBreak,
    };
  });
}

export function splitElements(elements: any[]): { articles; extraElements } {
  return {
    articles: elements.filter(row => row.rowType === RowTypes.PRODUCT),
    extraElements: <ExtraListElementRowInterface[]>elements.filter(row => [...GROUP_LIKE_ROW_TYPES, RowTypes.EXTRA].includes(row.rowType)),
  };
}
